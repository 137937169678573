import React, { Fragment } from "react"
import * as R from "ramda"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Prose from "../../components/prose"
import RichText from "../../components/richText"
import GetPrefix from "../../helpers/GetPrefix"
import Seo from "../../components/seo"
import LiftContent from "../../components/liftContent"
import Card from "../../components/card"
import CollectionGrid from "../../components/collectionGrid"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
import LayoutController from "../../components/layoutController"

const Expo = ({ data }) => {
  // content
  const content = R.path(["expo"], data)
  const slug = R.path(["slug"], content)
  const title = R.path(["title"], content)
  const subtitle = R.path(["subtitle"], content)
  const mainImage = R.path(["mainImage", "gatsbyImageData"], content)
  const video = R.path(["video", "file", "url"], content)
  const videoPosterImage = R.path(["videoPosterImage", "file", "url"], content)
  const richTextContent = R.path(["richText"], content)
  const contentTextShort = R.path(["contentTextShort"], content)
  const allSubpages = R.path(["subpages"], content)
  const hideSubpages = R.path(["hideSubpages"], content)
  const premises = R.path(["premises"], content)
  const articles = R.path(["articles"], content)
  const liftContent = R.path(["liftContent"], content)
  const mainImageSEO = R.path(["mainImage", "file", "url"], content)
  let status = R.path(["status"], content)
  const language =  R.path(["node_locale"], content)

  status = status ? status[0] : null
  const quickLinks = allSubpages
    ? allSubpages.filter(item => item.showInQuickLinks === true)
    : null
  const subpages = allSubpages
    ? allSubpages.filter(
        item =>
          item.showInQuickLinks === false || item.showInQuickLinks === null || item.hideSubpage === true
      )
    : null

  const QuickLinks = ({ links }) => {
    if (links.length) {
      return (
        <nav className="max-w-5xl mx-auto flex justify-center relative mt-[-2rem]">
          <ul className="list-none m-0 bg-blue px-5 w-11/12 md:w-auto md:px-10 py-5 flex flex-col md:flex-row md:flex-wrap md:items-center md:justify-center relative gap-x-5 gap-y-2 shadow-md">
            {quickLinks.map(link => {
              return (
                <li className="font-sans-medium mb-0">
                  { link.slug === 'kohteet-2022' ? (
                  <Link
                    to={GetPrefix(language, `/${slug}/kohteet`)}
                    className="text-text hover:!underline hover:!text-text flex underline-offset-2 items-center"
                  >
                    <span aria-hidden="true" className="mr-2">
                      <ArrowForwardIosIcon className="!fill-black/30 block !w-3 !h-3" />
                    </span>
                    {link.title}
                  </Link>
                  ) : (
                    <Link
                    to={GetPrefix(language, `/${slug}/${link.slug}`)}
                    className="text-text hover:!underline hover:!text-text flex underline-offset-2 items-center"
                  >
                    <span aria-hidden="true" className="mr-2">
                      <ArrowForwardIosIcon className="!fill-black/30 block !w-3 !h-3" />
                    </span>
                    {link.title}
                  </Link>
                  )}
                </li>
              )
            })}
          </ul>
        </nav>
      )
    } else {
      return null
    }
  }

  return (
    <LayoutController language={language}> 
      <Seo
        seoTitle={title}
        seoDesc={contentTextShort}
        url={typeof window !== "undefined" ? window.location.href : ""}
        image={mainImageSEO}
      />
      <section className="max-w-6xl m-auto mt-10 mb-20">
        <header className="text-center relative">
          <h1 className="mt-5">{title}</h1>
          <p className="font-bold mt-0.5">{subtitle}</p>
        </header>

        <div className="relative h-0 pb-[70%] md:pb-[50%]">
          <GatsbyImage
            className="w-full h-full !absolute top-0 left-0"
            image={mainImage}
            alt=""
          />
        </div>

        {!hideSubpages && quickLinks && <QuickLinks links={quickLinks} />}

        <div className="max-w-3xl m-auto mt-12 px-5">
          {richTextContent && <RichText richText={richTextContent} />}
        </div>

        {!hideSubpages && subpages && (
          <div className="max-w-6xl mx-auto mt-10 md:mt-20 px-5">
            <CollectionGrid>
              {subpages.map(teaser => {
                const teaserImage = R.path(
                  ["gatsbyImageData"],
                  teaser.mainImage
                )
                if (!teaser.hideSubpage)
                  return (
                    <Card
                      key={teaser.slug}
                      title={teaser.title}
                      image={teaserImage ? teaserImage : mainImage}
                      url={`${slug}/${teaser.slug}/`}
                      language={language}
                    />
                  )
                return
              })}
            </CollectionGrid>
          </div>
        )}
      </section>

      {liftContent && (
        <div className="mt-12 mb-20">
          <LiftContent pageContent={content} />
        </div>
      )}
    </LayoutController>
  )
}

export default Expo

// eslint-disable-next-line no-undef
export const expoQuery = graphql`
  query expoQuery($slug: String) {
    expo: contentfulMessu(slug: { eq: $slug }) {
      id
      slug
      node_locale
      status
      title
      subtitle
      contentTextShort
      color
      mainImage {
        gatsbyImageData(layout: CONSTRAINED, width: 1200, placeholder: BLURRED)
      }
      video {
        file {
          url
          fileName
          contentType
        }
      }
      videoPosterImage {
        file {
          url
        }
      }

      richText {
        raw
        references {
          ... on Node {
            ... on ContentfulAsset {
              id
              contentful_id
              __typename
              title
              description
              gatsbyImageData(width: 800)
              file {
                url
                fileName
              }
            }
          }
          ... on Node {
            ... on ContentfulHenkilo {
              id
              contentful_id
              __typename
              name
              contentText {
                contentText
              }
              image {
                gatsbyImageData(width: 300)
              }
            }
          }
          ... on Node {
            ... on ContentfulNosto {
              id
              contentful_id
              __typename
              title
              contentText {
                contentText
              }
              image {
                gatsbyImageData(width: 500)
              }
              buttonText
              buttonLink
              backgroundColor
              reverseImage
            }
          }
          ... on Node {
            ... on ContentfulPainike {
              id
              contentful_id
              __typename
              ctaText
              ctaTarget
              ctaColor
            }
          }
        }
      }
      hideSubpages
      subpages {
        node_locale
        ... on Node {
          ... on ContentfulPerusalasivu {
            __typename
            slug
            title
            mainImage {
              gatsbyImageData(
                layout: CONSTRAINED
                width: 400
                placeholder: BLURRED
              )
            }
            showInQuickLinks
            serviceCentre
            hideSubpage
          }
        }
      }
      premisesTitle
      premisesText {
        premisesText
      }
      premises {
        ... on Node {
          ... on ContentfulKohdesivu {
            slug
            isVirtual
            name
            mainImage {
              gatsbyImageData(layout: CONSTRAINED, width: 400, quality: 80)
            }
          }
        }
      }
      articlesTitle
      articlesText {
        articlesText
      }
      articles {
        ... on Node {
          ... on ContentfulArtikkelisivu {
            slug
            title
            mainImage {
              gatsbyImageData(layout: CONSTRAINED, width: 400, quality: 80)
            }
          }
        }
      }
      liftContent {
        ... on Node {
          ... on ContentfulNostolista {
            __typename
            id
            color
            buttonText
            buttonAction {
              ... on Node {
                ... on ContentfulKategoriasivu {
                  __typename
                  id
                  slug
                }
                ... on ContentfulArtikkelisivu {
                  __typename
                  id
                  slug
                }
                ... on ContentfulKohdesivu {
                  __typename
                  id
                  slug
                }
              }
            }
            title
          }
          ... on ContentfulVarinosto {
            __typename
            id
            backgroundColor
            title
            contentText {
              contentText
            }
            ctaButton1Text
            ctaButton1Action {
              ... on Node {
                ... on ContentfulArtikkelisivu {
                  __typename
                  slug
                  mainImage {
                    id
                    file {
                      url
                    }
                    gatsbyImageData(quality: 80, layout: CONSTRAINED)
                  }
                }
                ... on ContentfulKategoriasivu {
                  __typename
                  slug
                  mainImage {
                    id
                    file {
                      url
                    }
                    gatsbyImageData(quality: 80, layout: CONSTRAINED)
                  }
                }
              }
            }
          }
          ... on ContentfulSisaltoelementti {
            __typename
            id
            backgroundColor
            title
            contentText {
              contentText
            }
            image {
              id
              file {
                url
              }
              gatsbyImageData(quality: 80, layout: CONSTRAINED)
            }
            ctaButtonText
            ctaButtonAction
          }
          ... on ContentfulVideonosto {
            __typename
            id
            title
            contentText {
              contentText
            }
            videoUrl
            thumbNail {
              id
              file {
                url
              }
              gatsbyImageData(quality: 80, layout: CONSTRAINED)
            }
            ctaButtonText
            buttonSlug
          }
          ... on ContentfulTekstilinkkilista {
            __typename
            id
            title
            contentText {
              contentText
            }
            liftList {
              ... on Node {
                ... on ContentfulArtikkelisivu {
                  id
                  title
                  slug
                }
                ... on ContentfulKategoriasivu {
                  id
                  slug
                  title
                }
                ... on ContentfulKohdesivu {
                  id
                  name
                  slug
                }
              }
            }
          }
          ... on ContentfulTekstikentta {
            __typename
            title
            text {
              text
            }
            ctaButtonText
            ctaButtonSlug
            wide
          }
          ... on ContentfulKohdekartta {
            __typename
            id
            title
            premiseYear
            map {
              gatsbyImageData(quality: 80, layout: CONSTRAINED)
            }
          }
          ... on ContentfulAnimoidutNostot {
            __typename
            id
            title
            lifts {
              id
              title
              text {
                text
              }
              buttonText
              buttonLink
              buttonAction {
                ... on Node {
                  ... on ContentfulKategoriasivu {
                    __typename
                    id
                    slug
                  }
                  ... on ContentfulArtikkelisivu {
                    __typename
                    id
                    slug
                  }
                  ... on ContentfulKohdesivu {
                    __typename
                    id
                    slug
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
